
	import { defineComponent, reactive, ref, onMounted, watch, computed } from '@vue/runtime-core'
	import { Buttons, TableColumns } from '@/types/BasicComponent'
	import { FormRulesMap } from 'element-plus/lib/components/form/src/form.type'
	import { getGoodsCategoryList, getGoodsByCategoryId, getOrderImages, kfUpdateOrder } from '@/api/order'
  import { ElMessage, ElMessageBox } from 'element-plus'
  import { getFileBasePreviewUrl } from '@/api/file'
	import BasicFormDialog from '@/components/BasicPageLayout/BasicFormDialog.vue'
	import BasicTable from '@/components/BasicPageLayout/BasicTable.vue'
  import BasicImageList from '@/components/BasicPageLayout/BasicImageList.vue'
  
	export default defineComponent({
		components: {
			BasicFormDialog,
			BasicTable,
      BasicImageList
		},
    emits: ['reloadPageData'],
		setup(props, context){

			const modalShow = ref(false)
			
      const editingIndex = ref(-9) // >=0 修改 -1 添加 其他值 表示默认
      
      const categoryIndex = ref(-1)
      
      const errorMsg = ref("")
      
      const baseImgUrl = ref("")
      
      const isAjaxing = ref(0)
      
			const formData = reactive<AnyObject>({
				carNo: 				    "",
				driverLicenseNo: 	"",
				driverName: 		  "",
				driverPhone: 		  "",
				orderGoodsList: 	[],
				orderid: 			    0,
				orderCode: 			  "",
				vehicleLicenseNo: ""
			})
			
			const goodsInfo = reactive<AnyObject>({})
			
			const footerButtons = reactive<Buttons>([
				{
					label: '提交',
					type: 'primary',
					onClick: () => {
            if(isAjaxing.value){
              return !ElMessage.info('正在提交中...')
            }
            
            if(editingIndex.value >= -1){
              errorMsg.value = ('请先 “确定” 或 “取消” ' + (editingIndex.value >= 0 ? '修改' : '添加') + '货物！')
              setTimeout(() => {
                errorMsg.value = ""
              }, 3000)
              return !ElMessage.error(errorMsg.value)
            }
            
            ElMessageBox.confirm('更新订单信息', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'info'
            }).then(() => {
              isAjaxing.value = 1
              kfUpdateOrder(formData).then(() => {
                console.log('客服订单信息提交成功。')
                context.emit('reloadPageData', 1)
                ElMessage.success('已提交')
                isAjaxing.value = 0
                modalShow.value = false
              }).catch(() =>{
                console.log('客服订单信息提交失败！')
                isAjaxing.value = 0
              })
            })
            
            return true
					}
				}, 
				{
					label: '取消',
					type: 'default',
					onClick: () => (modalShow.value = false)
				}
			])
			
			const formRules = reactive<FormRulesMap>({
				/* 暂时不用检查必填项 carNo: [
					{ required: true, message: '请输入车牌号' },
				],
				driverLicenseNo: [
					{ required: true, message: '请输入驾驶证号' },
				],
				driverName: [
					{ required: true, message: '请输入司机姓名' },
				],
				driverPhone: [
					{ required: true, message: '请输入司机手机号' },
				],
				vehicleLicenseNo: [
					{ required: true, message: '请输入行驶证号' },
				] */
			})
			
			const goodsColumns = reactive<TableColumns>([
				{
					prop: 'goodsName',
					label: '名称',
					align: 'center'
				},
				{
					prop: 'unitDesc',
					label: '规格',
					align: 'center'
				},
				{
					prop: 'goodsNum',
					label: '数量',
					align: 'center'
				},
				{
					prop: 'unitPrice',
					label: '单价',
					align: 'center'
				},
				{
					prop: 'totalPrice',
					label: '总价',
					align: 'center'
				},
				{
					type: 'button',
					label: '操作',
					align: 'center',
					minWidth: '80px',
          className: 'sru-operation-cell', //Shipping-Record-Update
					buttons: [{
						type: 'text',
						icon: 'el-icon-edit', //修改
						onClick: scope => {
              editingIndex.value = scope.$index
              resetGoodsInfo(formData.orderGoodsList[editingIndex.value])
						},
            show: scope => (editingIndex.value !== scope.$index)
					}, {
						type: 'text',
						icon: 'el-icon-edit', //修改
            label: '正在编辑',
            show: scope => (editingIndex.value === scope.$index)
					}, {
						type: 'text',
						icon: 'el-icon-delete', //删除
						onClick: scope => {
              formData.orderGoodsList.splice(scope.$index, 1)
              editingIndex.value = -9
						},
            show: scope => (editingIndex.value !== scope.$index)
					}]
				}
			])
			
			const categoryList = reactive<AnyArray>([])
			
      const orderImages = reactive<AnyArray>([])
      
			const goodsData = computed(() => {
				return formData.orderGoodsList.map((obj: AnyObject, nth: number) => {
					let citem = categoryList.find((val: AnyObject) => (val.cid === obj.goodsCategoryId))
					let gitem = null
					let myNum = ""
          
          if(citem.goodsList && citem.goodsList.length){
            gitem = citem.goodsList.find((val: AnyObject) => (val.gid === obj.goodsId))
          }
          
          if(!gitem){
            gitem = {gname: ""}
          }
          
					switch(obj.countMode){
						case 1: myNum = (obj.volume + " 方"); break;
						case 2: myNum = (obj.weight + " 吨"); break;
						default: myNum = (obj.quantity + " 份"); break;
					}
          
					return {
						goodsName: 	(citem.cname + " " + gitem.gname),
						unitDesc:   obj.unitDesc,
						goodsNum: 	myNum,
						unitPrice:	(+obj.unitPrice || 0).toFixed(2),
						totalPrice:	(+obj.totalPrice || 0).toFixed(2)
					}
				})
			})
      
      const resetGoodsInfo = (infos: AnyObject) => {
        if(infos.goodsCategoryId){
          getGoodsInfoList(infos.goodsCategoryId)
        }
        
        goodsInfo.gid               = infos.gid             || null
        goodsInfo.orderId           = infos.orderId         || formData.orderid
        goodsInfo.countMode         = infos.countMode       || 1
        goodsInfo.goodsCategoryId   = infos.goodsCategoryId || 1
        goodsInfo.goodsId           = infos.goodsId         || 1
        goodsInfo.totalPrice        = infos.totalPrice      || ""
        goodsInfo.unitPrice         = infos.unitPrice       || ""
        goodsInfo.volume            = infos.volume          || ""
        goodsInfo.weight            = infos.weight          || ""
        goodsInfo.quantity          = infos.quantity        || ""
        goodsInfo.unitDesc          = infos.unitDesc        || "1270mm*630mm*1.5mm"
      }
      
			const showBox = (orderInfos: AnyObject) => {
        //console.log(orderInfos)
        
        formData.carNo            = orderInfos["carNo"           ]
        formData.driverLicenseNo  = orderInfos["driverLicenseNo" ]
        formData.driverName       = orderInfos["driverName"      ]
        formData.driverPhone      = orderInfos["driverPhone"     ]
        formData.orderGoodsList   = orderInfos["orderGoodsList"  ]
        formData.orderid          = orderInfos["orderid"         ]
        formData.orderCode        = orderInfos["orderCode"       ]
        formData.vehicleLicenseNo = orderInfos["vehicleLicenseNo"]
        
        editingIndex.value = -9
        isAjaxing.value = 0
        modalShow.value = true
        getOrderImageList(formData.orderid)
			}
			
      const getOrderImageList = async (oid: number) => {
        orderImages.splice(0, orderImages.length)
        const res1 = await getOrderImages(formData.orderid)
        if(res1.data && res1.data.data){
          res1.data.data.forEach((item: AnyObject) => {
            orderImages.push({
              url: baseImgUrl.value + item.fileguid,
              title: (item.fileName || "")
            })
          })
        }
      }
      
			const getGoodsInfoList = async (cidx: number) => {
				if(cidx){
					cidx = categoryList.findIndex((val: AnyObject) => (val.cid===cidx))
				}
				categoryIndex.value = cidx //从 0 开始
				if(!categoryList[cidx].goodsList){
					categoryList[cidx].goodsList = []
					const res2 = await getGoodsByCategoryId(categoryList[cidx].cid)
					if(res2.data && res2.data.data){
						categoryList[cidx].goodsList = res2.data.data.map((item: AnyObject) => {
							return { gid: item.goodsId, gname: item.goodsName }
						})
					}
				}
				goodsInfo.goodsId = categoryList[cidx].goodsList[0].gid //默认第一个
			}
			
			const autoChangeUnitDesc = (gid: number) => {
				const glist = categoryList[categoryIndex.value].goodsList
				const ginfo = glist.find((val: AnyObject) => (val.gid===gid))
				const gdesc = ginfo.gname.match(/[\d\.]+mm$/gim)
				if(gdesc){
					goodsInfo.unitDesc = ("1270mm*630mm*" + gdesc[0])
				}
			}
			
			const addOrSaveGoodsInfo = (type: number) => {
        if(type===1){
          if(goodsInfo.countMode == 1){
            goodsInfo.weight = 0
            goodsInfo.quantity = 0
          }else if(goodsInfo.countMode == 2){
            goodsInfo.volume = 0
            goodsInfo.quantity = 0
          }else{
            goodsInfo.volume = 0
            goodsInfo.weight = 0
          }
          
          if(editingIndex.value >= 0){ //修改
             Object.assign(formData.orderGoodsList[editingIndex.value], goodsInfo)
          } else {
            formData.orderGoodsList.push(Object.assign({}, goodsInfo))
          }
        }
        
        editingIndex.value = -9
        
        resetGoodsInfo({})
			}
			
			const onGoodsInfoChanged = (type: number) => {
				let mynum = 0.0
				if(goodsInfo.countMode==1){
					mynum = +goodsInfo.volume
				} else if(goodsInfo.countMode==2){
					mynum = +goodsInfo.weight
				} else{
					mynum = +goodsInfo.quantity
				}
				
				if(type === 0){ //修改的是总价
					if(mynum){
						goodsInfo.unitPrice = Math.round(goodsInfo.totalPrice / mynum * 100) / 100 //保留两位小数
					}else{
						goodsInfo.unitPrice = 0
					}
				} else {
					if(mynum){
						goodsInfo.totalPrice = Math.round(goodsInfo.unitPrice * mynum * 100) / 100 //保留两位小数
					}else{
						goodsInfo.totalPrice = 0
					}
				}
			}
      
      const showAddGoodsPanel = () => {
        if(editingIndex.value < -1){
          editingIndex.value = -1
        }else if(editingIndex.value === -1){
          editingIndex.value = -9
        }
      }
      
			onMounted(async () => {
        resetGoodsInfo({})
				const res1 = await getGoodsCategoryList()
				if(res1.data && res1.data.data){
					res1.data.data.forEach((item: AnyObject) => {
						categoryList.push({cid: item.categoryId, cname: item.categoryName})
					})
					if(categoryList.length){
						goodsInfo.goodsCategoryId = categoryList[0].cid //默认第一个
						getGoodsInfoList(0)
					}
				}
        const res2 = await getFileBasePreviewUrl()
        if(res2.data && res2.data.data){
          baseImgUrl.value = res2.data.data
        }
			})
			
			//因重复触发，改成 oninput 方式
			/* watch(() => goodsInfo.totalPrice, (newVal, oldVal) => {
				onGoodsInfoChanged(0)
			})
			watch(() => [goodsInfo.unitPrice, goodsInfo.volume, goodsInfo.weight, goodsInfo.quantity], (newVal, oldVal) => {
				onGoodsInfoChanged(1)
			}) */
			
			return {
				modalShow,
        editingIndex,
				formData,
				footerButtons,
				formRules,
				goodsColumns,
				goodsInfo,
				goodsData,
				categoryList,
				categoryIndex,
				getGoodsInfoList,
				autoChangeUnitDesc,
				addOrSaveGoodsInfo,
				onGoodsInfoChanged,
        showAddGoodsPanel,
        errorMsg,
        orderImages,
				showBox
			}
		}
	})
