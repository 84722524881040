import {  C6PageRequest, C6BaseResponse, C6PageResponse } from '@/types/API'
import { SourceType } from '@/types/Common'
import { c6Request } from '@/utils/request'
import { FileMetaData } from './file'

/** 获取货单列表 */
export function getOrderList(params: C6PageRequest<AnyObject>) {
  return c6Request.get<C6BaseResponse<C6PageResponse>>('backstage/order/getOrderPage', { params })
}

/** 更新货单校验状态 */
export function updateOrderStatus(orderId: number, warnStatus: number, description: string) {
  return c6Request.post<C6BaseResponse>(`backstage/order/updateOrderWarnStatus`, { orderId, warnStatus, description })
}

/** 根据运单id获取货单的装卸图片 */
export function getOrderImages(orderid: number) {
  return c6Request.get<C6BaseResponse>(`backstage/file/getFileByType`, { params: { sourceTypeId: SourceType.ORDER, sourceId: orderid } })
}

/** 货单流水列表 */
export function getOrderFlowList(params: C6PageRequest<QueryOrderFlowListParams>) {
  return c6Request.get<C6BaseResponse<C6PageResponse>>(`backstage/order/getOrderFlowPage`, { params })
}

/** 订单录入分页 */
export function getOrderEnterPage(params: C6PageRequest<AnyObject>) {
  return c6Request.get<C6BaseResponse>(`/backstage/xqjorder/getOrderList`, { params })
}

/** 客服更新订单 */
export function kfUpdateOrder(data: AnyObject) {
  return c6Request.post<C6BaseResponse>(`/backstage/xqjorder/kfUpdateOrder`, data)
}

/** 货物分类列表 */
export function getGoodsCategoryList() {
  return c6Request.get<C6BaseResponse>(`/order/ordergoods/getGoodsCategoryList`)
}

/** 货物名称列表 */
export function getGoodsByCategoryId(categoryId: number) {
  return c6Request.get<C6BaseResponse>(`/order/ordergoods/getGoodsByCategoryId`, { params: { categoryId } })
}

/** 获取轨迹校验结果 */
export function getOrderTrackValidateResult(orderId: number) {
  return c6Request.get<C6BaseResponse>(`/order/ordertrack/getOrderTrackValidateResult`, { params: { orderId } })
}

/** 退回订单货款或退回订单服务费，GSFee：Goods or Service Fee*/
export function returnOrderGSFee(data: OrderPayReturnData, feetype: number) {
  if(feetype === 1){
    return c6Request.post<C6BaseResponse>(`/backstage/payreturn/returnGoodsFee`, data) //退回货款
  }else{
    return c6Request.post<C6BaseResponse>(`/backstage/payreturn/returnServiceFee`, data) //退回服务费
  }
}

/** 获取轨迹校验结果 */
export function getPayReturnDetail(orderId: number, type: number /** 记录类型 1退货款 2退服务费 */) {
  return c6Request.get<C6BaseResponse>(`/backstage/payreturn/getOrderPayReturnDetail`, { params: { orderId, type } })
}

export interface QueryOrderFlowListParams {
  startTime: Date,
  endTime: Date,
  orderNo: string,
  xqjzName: string,
  jhbcName: string,
  areaCode: string
}

export interface OrderPayReturnData {
  orderId: number,
  amount: number,
  remark?: string,
  files: FileMetaData[]
}