
	import BasicTablePage from '@/components/BasicPageLayout/BasicTablePage.vue'
	import { getOrderEnterPage } from '@/api/order'
	import { defineComponent, onMounted, reactive, ref, toRef } from 'vue'
	import usePagination from '@/utils/composables/usePagination'
	import { Buttons, Inputs, TableColumns } from '@/types/BasicComponent'
	import ShippingRecordUpdate from '@/views/shipping/ShippingRecordUpdate.vue'
	
	export default defineComponent({
		components: {
			BasicTablePage,
			ShippingRecordUpdate
		},
		setup(){
			const recordUpdateBox = ref()
      
			const tablePagination = usePagination()
			
      const searchData = reactive<AnyObject>({})
      
      const tableData = reactive<AnyArray>([])
    	
			const searchInputs = reactive<Inputs>([
				{
					label: '创建日期',
					type: 'group',
					divider: '至',
					inputs: [{
						type: 'date',
						model: toRef(searchData, 'createTimeStart'),
						clearable: false,
						placeholder: '开始日期',
						width: '150px',
					}, {
						type: 'date',
						model: toRef(searchData, 'createTimeEnd'),
						clearable: false,
						placeholder: '结束日期',
						width: '150px'
					}]
				},
				{
				  label: '录入状态',
				  type: 'select',
				  placeholder: '请选择',
				  clearable: true,
				  options: [{label:'已录入', value: '2'}, {label:'未录入', value: '1'}],
				  model: toRef(searchData, 'enterStatus')
				},
				{
				  label: '货单编号',
				  type: 'input',
				  placeholder: '货单编号',
				  model: toRef(searchData, 'orderCode'),
				  width: '200px',
				  clearable: true
				},
				{
				  label: '旋切机主',
				  type: 'input',
				  placeholder: '旋切机主名称',
				  model: toRef(searchData, 'xqjzName'),
				  width: '200px',
				  clearable: true
				}
			])
			
			const searchButtons = reactive<Buttons>([
				{
				  label: '查询',
				  icon: 'el-icon-search',
				  onClick: () => {
            getTableData()
          }
				}
			])
			
			const tableColumns = reactive<TableColumns>([
				{
					prop: 'index',
					label: '序号',
					width: '60px',
					align: 'center'
				}, {
					prop: 'orderCode',
					label: '货单号',
					minWidth: '200px',
					align: 'center'
				}, {
					prop: 'xqjzName',
					label: '旋切机主',
					minWidth: '100px',
					align: 'center'
				}, {
					prop: 'carNo',
					label: '车牌号',
					minWidth: '100px',
					align: 'center'
				}, {
					prop: 'driverLicenseNo',
					label: '驾驶证号',
					minWidth: '200px',
          align: 'center'
				}, {
					prop: 'vehicleLicenseNo',
					label: '行驶证号',
					minWidth: '200px',
          align: 'center'
				}, {
					prop: 'driverName',
					label: '司机',
					minWidth: '80px',
          align: 'center'
				}, {
					prop: 'driverPhone',
					label: '司机手机号',
					minWidth: '100px',
          align: 'center'
				}, {
					prop: 'goodsName',
					label: '货物名称',
					minWidth: '200px',
          align: 'center'
				}, {
					prop: 'goodsUnitDesc',
					label: '规格',
					minWidth: '200px',
          align: 'center'
				}, {
					prop: 'goodsUnitPrice',
					label: '单价',
					minWidth: '80px',
          align: 'center'
				}, {
					prop: 'goodsAmount',
					label: '数量',
					minWidth: '80px',
          align: 'center'
				}, {
					prop: 'goodsTotalPrice',
					label: '总价',
					minWidth: '80px',
          align: 'center'
				}, {
					prop: 'createdTime',
					label: '创建时间',
          align: 'center',
          minWidth: '200px',
				}, {
					type: 'button',
					label: '操作',
          fixed: 'right',
					buttons: [{
						label: "编辑",
						onClick: scope => {
							recordUpdateBox.value.showBox(scope.row);
						}
					}]
				}
			])
			
      const getTableData = async () => {
      	const respone = await getOrderEnterPage(Object.assign({ 
      		pageNo: 	tablePagination.currentPage, 
      		pageSize: 	tablePagination.pageSize
      	}, searchData))
      	
        tableData.splice(0, tableData.length)
      	if(respone.data && respone.data.data){
      		(respone.data.data.records || []).forEach((item: AnyObject, index: number) => {
            const goodsInfo = (item.goodsList && item.goodsList.length ? item.goodsList[0] : {})
            const hasGoods = !!goodsInfo.gid
      			tableData.push({
      				"index": 		        (tablePagination.pageOffset + index),
              "orderid":          (item.orderid),
      				"orderCode": 	      item.orderCode,
              "xqjzName":         item.xqjzName,
      				"carNo":		        (item.carNo || ""),
      				"driverName":	      (item.driverName || ""),
      				"driverLicenseNo":  (item.driverLicenseNo || ""), //驾驶证号
      				"driverPhone":	    (item.driverPhone || ""),
              "vehicleLicenseNo": (item.vehicleLisenceNo || ""), //行驶证号
              "orderGoodsList":   (item.goodsList || []),
              "goodsName":        (hasGoods ? goodsInfo.categoryName + " " + goodsInfo.goodsName : ""),
              "goodsUnitDesc":    (hasGoods ? goodsInfo.unitDesc : ""),
              "goodsUnitPrice":   (hasGoods ? (+goodsInfo.unitPrice || 0).toFixed(2) : ""),
              "goodsTotalPrice":  (hasGoods ? (+goodsInfo.totalPrice || 0).toFixed(2) : ""),
              "goodsAmount":      (hasGoods ? getGoodsAmount(goodsInfo.countMode, goodsInfo.volume, goodsInfo.weight, goodsInfo.quantity) : ""),
      				"createdTime":	    item.createdtime
      			})
      		})
      	}

				console.log('dataset');
      }
      
      const getGoodsAmount = (cm: number, vl: number, wt: number, qt: number) => {
        switch(cm) {
          case 1: return vl + " 方"
          case 2: return wt + " 吨"
          case 3: return qt + " 份"
          default: return "??"
        }
      }
      
			onMounted(() => {
				getTableData()
			})
			
			return {
				searchInputs,
				searchButtons,
				tableColumns,
				tableData,
				tablePagination,
				recordUpdateBox,
        getTableData
			}
		}
	})
